import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import countriesData from '../../utils/countries.json';
import axios from 'axios';
import './navbar.css';

const Navbar = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('Nigeria');
  const [city, setCity] = useState(''); // New state for city
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const service = e.target.service.value.trim();
    const country = e.target.country.value.trim();
    const city = e.target.city.value.trim(); // Get city value from the form

    const payload = { service, country, city }; // Include city in the payload

    try {
      const res = await axios.post('https://www.checkaroundme.com/api/v1/checkaroundme/auth/search', payload);
      setIsLoading(false);
      history('/search', { state: { results: res.data.data } });
    } catch (error) {
      setIsLoading(false);
      console.error('Search failed:', error);
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const linkStyle = {
    fontSize: isMobile ? '10px' : '12px',
    fontFamily: 'Montserrat',
    marginTop: '10px',
  };

  const input = {
    display: 'flex',
    alignItems: 'center',
  };

  const inputSelectStyle = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: isMobile ? '8px' : '12px',
    height: isMobile ? '30px' : '40px',
    width: isMobile ? '70px' : '',
    marginTop: isMobile ? '' : '10px',
    marginLeft: isMobile ? '' : '10px',
  };

  const imgStyle = {
    width: isMobile ? '40px' : '60px',
    marginTop: isMobile ? '10px' : '20px',
    marginBottom: isMobile ? '' : '10px',
  };

  const buttonStyle = {
    fontFamily: 'Montserrat',
    fontSize: isMobile ? '8px' : '12px',
    height: '35px',
    width: isMobile ? '70px' : 'auto',
    background: 'green',
    marginTop: isMobile ? '' : '10px',
    color: 'white',
  };
  
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className={`navbar navbar-expand-sm navbar-light fixed-top ${isNavOpen ? 'overflow-auto show' : ''}`} >
      <div className="container-fluid">
        <Link className="navbar-brand text-white" to="/">
          <img src="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/6658-01.png" alt="CheckAroundMe Logo" style={imgStyle} />
        </Link>
        <ul className={`d-flex flex-row ${isNavOpen ? 'overflow-auto show' : ''} mb-lg-0`} style={{ alignItems: 'flex-start' }}>
          <li className="nav-item">
            <Link className="nav-link nav-link-white" to="/about" style={linkStyle}>About Us</Link>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-white" href="https://www.auth.checkaroundme.com" style={linkStyle}>Login</a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-link-white" href="https://www.auth.checkaroundme.com" style={linkStyle}>Sign UP</a>
          </li>
        </ul>

        {/* Update form to include city input */}
        <form className="d-flex flex-row navbar-nav" onSubmit={handleSearch} style={input}>
          <input
            className="form-control me-2 t-12"
            type="search"
            placeholder="Search Pro"
            aria-label="Service"
            name="service"
            style={inputSelectStyle}
          />
          <select
            className="form-select me-2"
            aria-label="Country"
            name="country"
            value={selectedCountry}
            onChange={handleCountryChange}
            style={inputSelectStyle}
          >
            <option value="">Select Country</option>
            {Object.entries(countriesData).map(([code, name]) => (
              <option key={code} value={name}>
                {name}
              </option>
            ))}
          </select>
          <input
            className="form-control me-2 t-12"
            type="text"
            placeholder="City"
            aria-label="City"
            name="city"
            value={city}
            onChange={handleCityChange}
            style={inputSelectStyle} // Add input for city
          />
          <button type="submit" className="btn btn-success" style={buttonStyle}>
            {isLoading ? 'Loading...' : 'Search'}
          </button>
        </form>

        {!isMobile && (
          <button className="navbar-toggler bg-white" type="button" onClick={toggleNav} aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        )}

        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
