import React from 'react'
import { MainContent, CarouselComponent } from '../components';

const Main = () => {
  return (
    <div>
      {/* <JumbotronBanner /> */}
      <CarouselComponent />
      {/* <BelowJumbotron /> */}
      <MainContent />
      
    </div>
  )
}

export default Main
