import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <h3>Contact Us</h3>
        <p>Email: <a href="mailto:support@checkaroundme.com">support@checkaroundme.com</a></p>
        <p>Phone: +234 915 955 8854</p>
        <p>Address: No 24D, Green Bay Estate, Lekki, Lagos State, Nigeria</p>
        <div className="footer-links">
          <a href="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/privacy-policy-public.html.html" className="footer-link">Privacy Policy</a>
          <a href="https://check-around-me-asset.s3.eu-west-3.amazonaws.com/terms-and-conditions-public.html" className="footer-link">Terms and Conditions</a>
        </div>
      </div>

      <div className="footer-right">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/checkaroundme" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
